import React from "react";
import "./LandingPage.css";
import logo from "./logo.png";

const LandingPage = () => {
    return (
        <div className="landing-page">
            {/* Hero Section */}
            <section className="hero">
                <div className="hero-content">
                    <div className="container">
                        <img src={logo} alt="Pixel2Coding" className="logo" />
                        <h1>New website coming soon</h1>
                        <p>
                            On-Demand Remote Web Development Experts for Your Business. Scale-up your team with
                            world-class coding experts available immediately.
                        </p>
                    </div>
                </div>
            </section>

            {/* Services Section */}
            <section className="services">
                <div className="container">
                    <h2>Our Services</h2>
                    <div className="service-list">
                        <div className="service-item">
                            <h3>Custom Web Development</h3>
                            <p>Tailored solutions for your business needs.</p>
                        </div>
                        <div className="service-item">
                            <h3>E-commerce Solutions</h3>
                            <p>Build robust online stores to grow your business.</p>
                        </div>
                        <div className="service-item">
                            <h3>Maintenance & Support</h3>
                            <p>Reliable ongoing support for your website.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section 
            <section className="about">
                <div className="container">
                    <h2>About Us</h2>
                    <p>
                        We are a team of experienced web developers dedicated to helping businesses succeed online.
                        Whether you need a new website or updates to an existing one, we deliver high-quality solutions.
                    </p>
                </div>
            </section>

            {/* About Section 
            <section className="about">
                <div className="container">
                    <h2>About Us</h2>
                    <p>
                        What are we doing
                        Our strategy is based on delivering world class results at competitive pricing. Each project includes QA testing and a dedicated project manager.
                        Services We Offer:
                        • Front-End Development/Engineering
                        • Web Design, UI/UX
                        • CMS Coding/Customization
                        • Backend Software Development
                        • Full Stack Developers
                        From simple web solutions, to complex platforms, we have been providing equally great service to any size clients.
                        Our fee structure offers ability to work on a project/task basis (taking into consideration an hourly rate) or using an hourly rate for part-time/ full-time hiring.
                    </p>
                </div>
            </section>

            {/* Contact Section */}
            <section className="contact">
                <div className="container">
                    <h2>Contact Us</h2>
                    <p>We’d love to hear from you! Reach out for more details.</p>
                    <a href="mailto:info@pixel2Coding.com" className="contact-email">
                        info@pixel2Coding.com
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer>
                <p>&copy; 2024 Pixel2Coding. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default LandingPage;
